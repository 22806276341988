<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Message ref="message" severity="error" :closable="true" v-if="errors.length">{{errors}}</Message>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="dropdownRegion">Region</label>
                                <Dropdown v-model="filters.region_id" :loading="loadingDropdownRegionFilter" :options="dataDropdownRegoinFilter" optionLabel="region_name" optionValue="region_id" placeholder="Pilih Region" :filter="true" :showClear="true" @filter="searchDropdownRegion($event, 'filter')" @change="changeWilayah('filter')"/>
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="dropdownWilayah">Wilayah</label>
                                <Dropdown v-model="filters.wilayah_id" :loading="loadingDropdownWilayahFilter" :options="dataDropdownWilayahFilter" optionLabel="wilayah_name" optionValue="wilayah_id" placeholder="Pilih Wilayah" :filter="true" :showClear="true" @filter="searchDropdownWilayah($event, 'filter')"/>
                            </div>
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="dropdownKlasifikasi">Klasifikasi</label>
                                <Dropdown v-model="filters.klasifikasi_id" :loading="loadingDropdownKlasifikasiFilter" :options="dataDropdownKlasifikasiFilter" optionLabel="label" optionValue="klasifikasi_id" placeholder="Pilih Klasifikasi" :filter="true" :showClear="true" @filter="searchDropdownKlasifikasi($event, 'filter')"/>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="userid" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="cust_code" header="Kode Customer">
                        <template #body="slotProps">
                            {{slotProps.data.cust_code}}
                        </template>
                    </Column>
                    <Column field="cust_name" header="Nama Customer">
                        <template #body="slotProps">
                            {{slotProps.data.cust_name}}
                        </template>
                    </Column>
                    <Column field="klasifikasi" header="Klasifikasi">
                        <template #body="slotProps">
                             <div v-if="slotProps.data.klasifikasi_id !== null"> 
                                 {{slotProps.data.klasifikasi_id}}-{{slotProps.data.master_klasifikasi.klasifikasi_name}} 
                            </div>
                        </template>
                    </Column>
                    <Column field="region_name" header="Region">
                        <template #body="slotProps">
                             <div v-if="slotProps.data.wilayah_id !== null"> 
                                <div v-if="slotProps.data.master_wilayah.region_id !== null"> 
                                    {{slotProps.data.master_wilayah.master_region.region_name}} 
                                </div>
                            </div>
                        </template>
                    </Column>
                    <Column field="wilayah_name" header="Wilayah">
                        <template #body="slotProps">
                             <div v-if="slotProps.data.wilayah_id !== null"> 
                                 {{slotProps.data.master_wilayah.wilayah_name}} 
                            </div>
                        </template>
                    </Column>
					<Column header="Aksi" headerStyle="min-width:10rem;">
						<template #body="slotProps">
							<Button icon="pi pi-refresh" class="p-button-rounded p-button-danger mr-2" @click="resetItem(slotProps.data)"  v-if="permission.includes('update')"/>
						</template>
					</Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

				<Dialog v-model:visible="resetItemDialog" :style="{width: '450px'}" header="Konfirmasi" :modal="true" class="p-fluid">
                    <div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="item">Apakah anda akan mereset gps dari customer <b>{{item.cust_code}} - {{item.cust_name}}</b> ini ?</span>
					</div>
					<template #footer>
						<Button label="Tidak" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Ya" icon="pi pi-check" class="p-button-text" @click="resetingItem" :loading="loadingReset"/>
					</template>
				</Dialog>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';

export default {
    data() {
        return {
            // loading
            loading: false,
            loadingReset: false,
            loadingDropdownRegionFilter: false,
            loadingDropdownWilayahFilter: false,
            loadingDropdownKlasifikasiFilter: false,
            loadingExcel: false,
            loadingCsv: false,

            // dataDropdown
            dataDropdownRegoinFilter: null,
            dataDropdownWilayahFilter: null,
            dataDropdownKlasifikasiFilter: null,

            // filter
            filters: {
                region_id: null,
                wilayah_id: null,
                klasifikasi_id: null,
            },

            // edit/reset
            item: {},
            resetItemDialog: false,

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorAdd', {});
        this.$store.commit('setErrorEdit', {});
        this.searchDropdownRegion('');
        this.searchDropdownKlasifikasi('');
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    computed:{
        ...mapGetters(['errors', 'errorEdit', 'errorAdd']),
		...mapGetters("auth", ["permission"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DROPDOWN
        searchDropdownRegion(event, purpose, valueEdit){
            setTimeout(() => {
                if(valueEdit){
                    this.$refs.drgn.filterValue = valueEdit;
                }

                if(purpose == "filter"){
                    this.loadingDropdownRegionFilter = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-region-v2',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "filter"){
                        this.dataDropdownRegoinFilter = res.data.data;
                        this.loadingDropdownRegionFilter = false;
                    }else if(purpose == null){
                        this.dataDropdownRegoinFilter = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        changeWilayah(purpose){
            if(purpose == 'filter'){
                this.filters.wilayah_id= null;
                this.dataDropdownWilayahFilter= null;
                this.searchDropdownWilayah('','filter');
            }
        },
        searchDropdownWilayah(event, purpose, valueEdit){
            setTimeout(() => {
                if(this.filters.region_id){

                    if(valueEdit){
                        this.$refs.dwlyh.filterValue = valueEdit;
                    }

                    if(purpose == "filter"){
                        this.loadingDropdownWilayahFilter = true;
                    }

                    this.axios({
                        method: 'GET',
                        url: process.env.VUE_APP_ROOT_API + 'web/select2/master-wilayah',
                        params: {
                            "search" : valueEdit ? valueEdit : event.value,
                            "region_id" : this.filters.region_id
                        }
                    })
                    .then(res => {

                        if(purpose == "filter"){
                            this.dataDropdownWilayahFilter = res.data.data;
                            this.loadingDropdownWilayahFilter = false;
                        }else if(purpose == null){
                            this.dataDropdownWilayahFilter = res.data.data;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                }
            }, 250);
        },
        searchDropdownKlasifikasi(event, purpose, valueEdit){
            setTimeout(() => {

                if(valueEdit){
                    this.$refs.dklsf.filterValue = valueEdit;
                }

                if(purpose == "filter"){
                    this.loadingDropdownKlasifikasiFilter = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-klasifikasi',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "filter"){
                        this.dataDropdownKlasifikasiFilter = res.data.data;
                        this.loadingDropdownKlasifikasiFilter = false;
                    }else if(purpose == null){
                        this.dataDropdownKlasifikasiFilter = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // RESET
		resetItem(item) {
			this.item = item;
			this.resetItemDialog = true;
		},
		hideDialog() {
			this.resetItemDialog = false;
		},
		resetingItem() {
            this.loadingReset = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-reset-gps/reset',
                data: {
                    "cust_id" : this.item.cust_id,
                },
            })
            .then((res) => {
                this.loadingReset = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Direset', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    this.item = {};
                    this.resetItemDialog = false;
                    this.getDataTable();
                }
            })
            .catch((err) => {
                console.log(err);
                this.loadingReset = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Direset', life: 3000,});
            });
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-reset-gps',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "region_id" : this.filters.region_id,
                    "wilayah_id" : this.filters.wilayah_id,
                    "klasifikasi_id" : this.filters.klasifikasi_id,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
            })
            .catch((err) => {
                console.log(err);
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-reset-gps/export',
                responseType: 'blob',
                params: {
                    "ext" : ext,
                    "region_id" : this.filters.region_id,
                    "wilayah_id" : this.filters.wilayah_id,
                    "klasifikasi_id" : this.filters.klasifikasi_id,
                }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Master Reset GPS Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>